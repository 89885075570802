import React, { FC, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import env from './env';

const Home = lazy(() => import('./screens/Home/Home'));
const ProjectByTag = lazy(() => import('./screens/Project/ProjectByTag'));
const Complete = lazy(() => import('./screens/Complete/Complete'));
const ProjectPrint = lazy(() => import('./screens/Project/ProjectPrint'));
const Project = lazy(() => import('./screens/Project/Project'));

const AppRoutes: FC = () => {
  return (
    <Switch>
      <Route exact path="/saved" component={Home} />
      <Route exact path="/download" component={Home} />
      <Route exact path="/tag/:tagName" component={ProjectByTag} />
      <Route exact path="/:id/complete" component={Complete} />
      <Route exact path="/:id/print" component={ProjectPrint} />
      <Route exact path="/:id" component={Project} />
      {/* <Route exact path="/" component={Home} /> */}
      <Route
        render={(props) => {
          const urlParams = new URLSearchParams(props.location.search);
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const tag = Array.from(urlParams).find(([key, _]) => key.toLowerCase() === 'tag');

          if (env.FUSION_OF_HONOR_OF1_URLS === 'true' && !!tag) {
            const [tagKey, tagName] = tag;
            urlParams.delete(tagKey);

            return (
              <Redirect
                to={{
                  pathname: `/tag/${tagName}`,
                  search: urlParams.toString(),
                }}
              />
            );
          } else {
            return <Home />;
          }
        }}
      />
    </Switch>
  );
};

export default AppRoutes;
